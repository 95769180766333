import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect } from 'react';
import { getVerificationSessionCookieName } from '.';
import { ClientConfigType } from '../../../../clientConfig';
import { AUTO_INSTANT_ACTIVATION_PARAMS, OPENID_PARAMS } from '../../../../types/openid_enum';
import { ClientConfigContext } from '../../../clientConfigContext';
import { useBrand } from '../../../hooks/useBrand';
import useCookie from '../../../hooks/useCookie';
import { useMyChartRedirect } from '../../../hooks/useMyChartRedirect';
import { useOidcState } from '../../../hooks/useOidcState';
import { authorizeByIdentityVerification } from '../../../services/pimsClient';
import getAccessToken from '../../../util/getAccessToken';
import { redirectWithSoftLogout } from '../../../util/sessionTimeout';
import withLogger, { ComponentLoggerProps } from '../../HOC/WithLogger';
import CustomerServiceCard from '../../containers/CustomerServiceCard';
import RedirectButtonCard from '../../containers/RedirectButtonCard';
import TransitionCard from '../../containers/TransitionCard';
import { AuthenticatedRoutes } from '../../routers';
import { VENDOR_NAME } from './clear';
import { IdentityVerificationState } from './types';

enum PageState {
    Authorizing,
    RedirectToProve,
    Error,
}

/**
 * The Identity Verification Redirect Page.
 * After completing identity verification, the user is redirected to this page.
 */
export const RedirectPage: React.FC<ComponentLoggerProps> = ({ log }) => {
    const [pageState, setPageState] = React.useState<PageState>(PageState.Authorizing);
    const { b2cTenantConfig, pimsApiUrl } = useContext<ClientConfigType>(ClientConfigContext);
    const { getSamlSsoPage } = useMyChartRedirect();
    const { crossRegionEnabled } = useBrand();
    const { instance, accounts } = useMsal();

    const state = useOidcState<IdentityVerificationState>({
        isRequired: true,
        log,
    });

    // Show the new Identity Verification page if the user already has a clear session cookie or if the feature is enabled
    const azureUserId = accounts?.[0]?.localAccountId || '';
    const verificationSessionCookieName = getVerificationSessionCookieName(VENDOR_NAME, azureUserId); // In the future, this could loop over multiple vendors if needed
    const [verificationSessionId] = useCookie(verificationSessionCookieName);

    /* Display the Customer Service card if the verification session, state, or redirect_uri is missing */
    if (!verificationSessionId || !state || !state.redirect_uri) {
        return <CustomerServiceCard variant='closable' />;
    }

    const authorize = async (sessionId: string, redirectUrl: string) => {
        const jwt = await getAccessToken(instance, accounts, b2cTenantConfig.scopes, log);
        try {
            const { authorizedSystem } = await authorizeByIdentityVerification(jwt, pimsApiUrl, VENDOR_NAME, sessionId);
            let myChartSamlSSOUrl: string;
            if (state?.is_cross_region && crossRegionEnabled) {
                myChartSamlSSOUrl = getSamlSsoPage(authorizedSystem);
            } else {
                myChartSamlSSOUrl = redirectUrl;
            }
            redirectWithSoftLogout(instance, myChartSamlSSOUrl);
        } catch (e) {
            setPageState(PageState.Error);
        }
    };

    useEffect(() => {
        if (pageState == PageState.Authorizing && verificationSessionId) {
            authorize(verificationSessionId, state.redirect_uri);
        }
    }, [pageState]);

    if (pageState === PageState.Authorizing) {
        return <TransitionCard title='Creating your account' />;
    } else {
        const params = {
            [OPENID_PARAMS.BRAND]: state.brand,
            [OPENID_PARAMS.CROSS_REGION]: !!state.is_cross_region,
            [OPENID_PARAMS.EHR_SYSTEM]: state.ehrSystem,
            [OPENID_PARAMS.REDIRECT_URI]: state.redirect_uri,
            [AUTO_INSTANT_ACTIVATION_PARAMS.REGISTER_PHONE]: true,
        };

        return (
            <RedirectButtonCard
                title='Provide additional information'
                content='Please provide additional information to help us locate your medical record.'
                url={AuthenticatedRoutes.RootRoutes.Registration}
                onNext={() => {
                    // TODO: Analytics will need to be added here
                    console.log('Redirecting to Prove Flow');
                }}
                params={params}
            />
        );
    }
};

export default withLogger(RedirectPage, { eventSource: 'RedirectPage' });
