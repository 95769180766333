/*
 * Strictly to be used by the react tsx pages. The SPI.Commons configuration are not available on the react page.
 * Thus, the clientConfigs are passed in as a parameter.
 */
import { type AmplitudeIdentifiers } from '../../clientConfig';
import {
    PimsActivateMyChartResponse,
    PimsAuthorizationResponse,
    PimsErrorResponse,
    PimsGetAuthorizationResponse,
    PimsGetVerificationSessionResponse,
    PimsPostVerificationSessionRequest,
    PimsPostVerificationSessionResponse,
} from '../../router/types';
import {
    ActivationCodeAuthorizationEvidence,
    DemographicsAuthorizationEvidence,
    InstantActivationEvidence,
} from '../../types/authorizationEvidence';
import { PimsConsumer } from '../../types/consumer';
import { Demographics } from '../../types/demographics';
import { MFAVerifyType } from '../../types/mfa';
import { OPENID_PARAMS } from '../../types/openid_enum';
import { convertDateFormat } from '../../utils/dateUtils';
import { CORRELATION_ID_HEADER, getClientCorrelationId, getLogMetadata } from '../logger/logMetadata';
import { getAmplitudeIdentifiers } from '../services/amplitudeClient';

type AuthorizationEvidenceRequest = {
    authorizationEvidence:
        | DemographicsAuthorizationEvidence
        | ActivationCodeAuthorizationEvidence
        | InstantActivationEvidence;
    amplitudeIdentifiers?: AmplitudeIdentifiers;
};

export type SendVerificationCodeRequest = {
    jwt: string;
    pimsApiUrl: string;
    value: string;
    language: string;
    mode?: string;
};

export type VerifyCodeRequest = {
    jwt: string;
    pimsApiUrl: string;
    code: string;
    mode?: string;
    email?: string;
    proveBypass?: boolean;
};

export type ChangePasswordRequest = {
    jwt: string;
    pimsApiUrl: string;
    existingpassword: string;
    newpassword: string;
    confirmpassword: string;
    language: string;
    brand: string;
};

export type AutoInstantActivationParameters = {
    signin: string;
    userId: string;
    ehrSystem: string;
    nonPatientIdType?: string;
    brand?: string;
};

export type AdditionalAuthParameters = {
    brand: string;
    ehrSystem?: string;
};

export type AuthorizationRequest = {
    jwt: string;
    pimsApiUrl: string;
    authorizationEvidence:
        | DemographicsAuthorizationEvidence
        | ActivationCodeAuthorizationEvidence
        | InstantActivationEvidence;
    additionalParameters?: AutoInstantActivationParameters | AdditionalAuthParameters;
    system?: string;
    proveBypass?: string;
};

export type LinkDemographicsRequest = {
    jwt: string;
    demographics: Demographics;
    pimsApiUrl: string;
    system: string;
};

export const PROVE_BYPASS_HEADER = 'prove-verification-bypass';

function buildStandardHeaders(jwt: string): Record<string, string> {
    return {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        [CORRELATION_ID_HEADER]: getClientCorrelationId(),
    };
}

export async function linkWithDemographics({ jwt, demographics, pimsApiUrl, system }: LinkDemographicsRequest) {
    if (demographics.dateOfBirth) {
        demographics.dateOfBirth = convertDateFormat(demographics.dateOfBirth);
    }
    const headers: Record<string, string> = buildStandardHeaders(jwt);
    if (system) {
        headers['x-spi-domain'] = system;
    }
    return await fetch(`${pimsApiUrl}/v1/consumer/linkWithDemographics?${getLogMetadata()}`, {
        headers,
        method: 'POST',
        body: JSON.stringify({
            demographics,
        }),
    });
}

export async function activateMyChart(
    jwt: string,
    pimsApiUrl: string,
    ehrSystem: string,
): Promise<PimsActivateMyChartResponse> {
    const headers = buildStandardHeaders(jwt);
    const response = await fetch(`${pimsApiUrl}/v1/consumer/activateMyChart`, {
        headers,
        body: JSON.stringify({
            ehrSystem,
        }),
        method: 'POST',
    });
    const body = (await response.json()) as PimsActivateMyChartResponse;
    return body;
}

export async function authorizeByActivationCode(authRequest: AuthorizationRequest) {
    const { jwt, pimsApiUrl, additionalParameters } = authRequest;
    const authorizationEvidence = authRequest.authorizationEvidence as ActivationCodeAuthorizationEvidence;
    if (authorizationEvidence.dateOfBirth) {
        authorizationEvidence.dateOfBirth = convertDateFormat(authorizationEvidence.dateOfBirth);
    }
    const amplitudeIdentifiers: AmplitudeIdentifiers = getAmplitudeIdentifiers();
    return sendAuthorizationRequest(
        {
            jwt,
            authorizationEvidence,
            additionalParameters,
            pimsApiUrl: `${pimsApiUrl}/v1/consumer/authorizeByActivationCode?${getLogMetadata()}`,
        },
        amplitudeIdentifiers.amplitudeDeviceId,
        amplitudeIdentifiers.amplitudeSessionId,
    );
}

export async function authorizeByDemographics(authRequest: AuthorizationRequest) {
    const { jwt, pimsApiUrl, system, proveBypass, additionalParameters } = authRequest;
    const authorizationEvidence = authRequest.authorizationEvidence as DemographicsAuthorizationEvidence;
    if (authorizationEvidence.demographics.dateOfBirth) {
        authorizationEvidence.demographics.dateOfBirth = convertDateFormat(
            authorizationEvidence.demographics.dateOfBirth,
        );
    }
    const amplitudeIdentifiers = getAmplitudeIdentifiers();
    return sendAuthorizationRequest(
        {
            jwt,
            authorizationEvidence,
            additionalParameters,
            pimsApiUrl: `${pimsApiUrl}/v1/consumer/authorizeByDemographics?${getLogMetadata()}`,
            system,
            proveBypass,
        },
        amplitudeIdentifiers.amplitudeDeviceId,
        amplitudeIdentifiers.amplitudeSessionId,
    );
}

export async function authorizeByInstantActivation(authRequest: AuthorizationRequest) {
    const { jwt, pimsApiUrl, system, additionalParameters } = authRequest;
    const authorizationEvidence = authRequest.authorizationEvidence as InstantActivationEvidence;
    if (authorizationEvidence.dateOfBirth) {
        authorizationEvidence.dateOfBirth = convertDateFormat(authorizationEvidence.dateOfBirth);
    }
    if (authorizationEvidence.instantActivationCode) {
        authorizationEvidence.instantActivationCode = authorizationEvidence.instantActivationCode.replace(' ', '+');
    }
    return sendAuthorizationRequest({
        jwt,
        authorizationEvidence,
        additionalParameters,
        pimsApiUrl: `${pimsApiUrl}/v1/consumer/authorizeByInstantActivation?${getLogMetadata()}`,
        system,
    });
}

async function sendAuthorizationRequest(
    authRequest: AuthorizationRequest,
    amplitudeDeviceId?: string,
    amplitudeSessionId?: number,
) {
    const { jwt, authorizationEvidence, pimsApiUrl, system, proveBypass, additionalParameters } = authRequest;
    const headers: Record<string, string> = buildStandardHeaders(jwt);
    if (system) {
        headers['x-spi-domain'] = system;
    }
    if (proveBypass) {
        headers[PROVE_BYPASS_HEADER] = proveBypass;
    }
    const body: AuthorizationEvidenceRequest = {
        authorizationEvidence,
        ...additionalParameters,
    };
    if (amplitudeDeviceId && amplitudeSessionId) {
        body.amplitudeIdentifiers = { amplitudeDeviceId, amplitudeSessionId };
    }
    return await fetch(pimsApiUrl, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function sendVerificationCode({
    jwt,
    value,
    pimsApiUrl,
    language,
    mode = MFAVerifyType.PhoneVerifyType,
}: SendVerificationCodeRequest) {
    language = language === 'espanol' || language === 'es' ? 'es' : 'en';

    const searchParams = new URLSearchParams(document.location.search);
    const brand = searchParams.get(OPENID_PARAMS.BRAND) || '';
    const body = {
        mode: mode,
        value: value,
        brand: brand,
        language: language,
    };

    const headers: Record<string, string> = buildStandardHeaders(jwt);
    return await fetch(`${pimsApiUrl}/v1/consumer/sendVerificationCode?${getLogMetadata()}`, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function verifyCode({
    jwt,
    code,
    pimsApiUrl,
    mode = MFAVerifyType.PhoneVerifyType,
    email = '',
    proveBypass = false,
}: VerifyCodeRequest) {
    const body = {
        mode: mode,
        code: code,
        ...(email && { email }),
    };
    const headers: Record<string, string> = {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        [CORRELATION_ID_HEADER]: getClientCorrelationId(),
    };
    if (proveBypass) {
        headers[PROVE_BYPASS_HEADER] = 'bypass';
    }

    return await fetch(`${pimsApiUrl}/v1/consumer/verifyCode?${getLogMetadata()}`, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function changePassword(request: ChangePasswordRequest) {
    const { brand, existingpassword, newpassword, confirmpassword, pimsApiUrl, jwt } = request;
    const language = request.language === 'espanol' || request.language === 'es' ? 'es' : 'en';

    const body: { [key: string]: string | boolean } = {
        existingpassword,
        newpassword,
        confirmpassword,
        sendEmail: true,
        language,
    };
    if (brand) {
        body.brand = brand;
    }

    const headers = buildStandardHeaders(jwt);

    return await fetch(`${pimsApiUrl}/internal/v1/account/changePassword?${getLogMetadata()}`, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
    });
}

export async function getConsumer(jwt: string, pimsApiUrl: string): Promise<PimsConsumer> {
    const headers = buildStandardHeaders(jwt);
    const response = await fetch(`${pimsApiUrl}/v1/consumer?${getLogMetadata()}`, {
        headers,
        method: 'GET',
    });

    const consumer = await response.json();
    return consumer;
}

export async function getAutoInstantActivationAuthorization(
    jwt: string,
    pimsApiUrl: string,
    ehrSystem: string,
    instantActivationCode: string,
): Promise<PimsGetAuthorizationResponse> {
    const getAuthorizationParams = new URLSearchParams({
        ehrSystem,
        instantActivationCode,
    });
    const response = await fetch(
        `${pimsApiUrl}/v1/consumer/instantactivation/authorization?` + getAuthorizationParams.toString(),
        {
            headers: {
                Authorization: 'Bearer ' + jwt,
                correlationId: getClientCorrelationId(),
            },
            method: 'GET',
        },
    );
    const body = (await response.json()) as PimsGetAuthorizationResponse;
    return body;
}

export async function postIdentityVerificationSession({
    jwt,
    pimsApiUrl,
    brand,
    ehrSystem,
    deviceType,
    redirectUri,
    vendor,
    language = 'en',
}: PimsPostVerificationSessionRequest): Promise<PimsPostVerificationSessionResponse> {
    const body = { brand, ehrSystem, deviceType, redirectUri, language };

    const response = await fetch(`${pimsApiUrl}/v2/consumer/verification-session/${vendor}`, {
        headers: {
            Authorization: 'Bearer ' + jwt,
            correlationId: getClientCorrelationId(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...body, deviceType: deviceType.toLowerCase() }),
        method: 'POST',
    });

    const { id, token } = await response.json();

    return { verificationSessionId: id, sessionTokenId: token };
}

export async function getIdentityVerificationSession(
    jwt: string,
    pimsApiUrl: string,
    vendor: string,
    sessionId: string,
): Promise<PimsGetVerificationSessionResponse> {
    const response = await fetch(`${pimsApiUrl}/v2/consumer/verification-session/${vendor}/${sessionId}`, {
        headers: {
            Authorization: 'Bearer ' + jwt,
            correlationId: getClientCorrelationId(),
        },
        method: 'GET',
    });
    const verificationSession = (await response.json()) as PimsGetVerificationSessionResponse;
    return verificationSession;
}
export async function authorizeByIdentityVerification(
    jwt: string,
    pimsApiUrl: string,
    vendor: string,
    sessionId: string,
): Promise<PimsAuthorizationResponse> {
    const body = {
        sessionId: sessionId,
    };
    const response = await fetch(`${pimsApiUrl}/v2/consumer/authorize/identityVerification/${vendor}`, {
        headers: {
            Authorization: 'Bearer ' + jwt,
            correlationId: getClientCorrelationId(),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
    });

    if (response.status >= 400) {
        const errorResponse = (await response.json()) as PimsErrorResponse;
        throw new Error(`Failed to authorize: ${errorResponse.message}`);
    }

    const authorizationResult = (await response.json()) as PimsAuthorizationResponse;
    return authorizationResult;
}
